import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.svg"
const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="404: Not found" />
      <section id="notFound">
        <img src={logo} id="logo" alt="acces construction" />
        <h1>{t("not found title")}</h1>
        <p>{t("not found text")}</p>
        <a href="/">{t("back btn")}</a>
      </section>
    </Layout>
  )
}

export default NotFoundPage
